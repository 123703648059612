export default [
    {
        "name": "facebook",
        "text": "Facebook",
        "route": "https://financeiro.bbhost.com.br/link.php?id=202",
        "icon": ["fab", "facebook"],
        "nav": false,
        "color": "facebook",
        "event": "open_facebook"
    },
    {
        "name": "instagram",
        "text": "Instagram",
        "route": "https://financeiro.bbhost.com.br/link.php?id=201",
        "icon": ["fab", "instagram"],
        "nav": false,
        "color": "rose",
        "event": "open_instagram"
    }
];