export default [
    {
        "name": "legal",
        "text": {
            "br": "Políticas",
            "en": "Legal"
        },
        "link": "/legal",
        "type": "internal"
    },
    {
        "name": "ajuda",
        "text": {
            "br": "Ajuda",
            "en": "Help"
        },
        "link": "https://financeiro.bbhost.com.br/knowledgebase.php",
        "type": "external"
    },
    {
        "name": "sobrenos",
        "text": {
            "br": "Sobre nós",
            "en": "About US"
        },
        "link": "/sobrenos",
        "type": "internal"
    }
];