import social from "./social";
import contato from "./contato";
import our_differences from "./our_differences";
import featured_products from "./featured_products";
import certifications from "./certifications";
import navs from "./navs";
import links from "./links";
import pages from "./pages";
import getBGPath_ from "./bg";
import getBGParceriaPath from "./parceria";

export const SOCIAL = social;
export const CONTATO = contato;
export const CERTIFICATIONS = certifications;
export const OUR_DIFFERENCES = our_differences;
export const FeaturedProducts = featured_products;
export const NAVS = navs;
export const LINKS = links;
export const PAGES = pages;
export const getBGPath = getBGPath_;
export const getBGParceriaURL = getBGParceriaPath;

import { baremetal_faqs } from "./products/baremetal";

export const BAREMETAL_FAQS = baremetal_faqs;