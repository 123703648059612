export default [
    {
        "name": "manrs",
        "text": "manrs.",
        "subText": {
            "br": "Certificação de Boas Praticas de segurança na internet."
        },
        "logo": "/img/certifications/manrs.png",
        "link": "https://financeiro.bbhost.com.br/link.php?id=203",
        "height": "27px",
        "width": "110px"
    }
];