import defaultBG from "public/img/bg/capa_fundo_dedicado.png";
import hallowenBG from "public/img/bg/hallowen.png";
import natalBG from "public/img/bg/bbhost_natal_2.png";

import defaultBG_Mobile from "public/img/bg/mobile/capa_fundo_dedicado_mobile.png";
import hallowenBG_Mobile from "public/img/bg/mobile/hallowen.png";
import natalBG_Mobile from "public/img/bg/mobile/bbhost_natal_mobile.png";

import { isMobile } from 'react-device-detect';

export default function getBGPath_(name, mobileForce = false)
{    
    if(isMobile || mobileForce)
        return getBGMobilePath(name);

    return getBGDesktopPath(name);
}

function getBGDesktopPath(name)
{    
    if(BGPath[name] !== undefined)
        return BGPath[name];    

    return BGPath[`default`];
}

function getBGMobilePath(name)
{    
    if(BGMobileConfig[name] !== undefined)
        return BGMobileConfig[name];
    return BGMobileConfig[`default`];
}


const BGPath = {
    "default": defaultBG,
    "hallowen": hallowenBG,
    "natal": natalBG,
    "pre_natal": natalBG,
    "ano_novo": natalBG
};

const BGMobileConfig = {
    "default": defaultBG_Mobile,
    "hallowen": hallowenBG_Mobile,
    "natal": natalBG_Mobile,
    "pre_natal": natalBG_Mobile,
    "ano_novo": natalBG_Mobile
};