import React from "react";
import Head from "next/head";
import dynamic from 'next/dynamic';

import { NextSeo, DefaultSeo } from 'next-seo';

import { makeStyles } from "@material-ui/core/styles";

import Header from "/components/Header/Header.js";
// const Header = dynamic(() => import('/components/Header/Header.js'), { ssr: true });
const HeaderNavItem = dynamic(() => import('/components/Header/HeaderNavItem.js'));
const SocialNav = dynamic(() => import('/components/Header/SocialNav.js'));
const ActionNav = dynamic(() => import('/components/Header/ActionNav.js'));
const LocationNav = dynamic(() => import('/components/Header/LocationNav.js'));
const Parallax = dynamic(() => import('/components/Parallax/Parallax.js'));
const List = dynamic(() => import('@material-ui/core/List'));

import navbarsStyle from "/styles/jss/bbhost/pages/componentsSections/navbarsStyle.js";

// Sections for this page
// import HeaderSectionParallax from "pages-sections/HeaderSectionParallax.js";
const HeaderSectionParallax = dynamic(() => import('pages-sections/HeaderSectionParallax.js'), { ssr: false });
const HeaderSectionSliderParallax = dynamic(() => import('pages-sections/HeaderSectionSliderParallax.js'), { ssr: false });

const styles = {
    ...navbarsStyle
};

const useStyles = makeStyles(styles);
import { NAVS, CONTATO, PAGES, getBGPath } from "configs/index";
import { getDomainByENV } from "constants.js";
import { isMobile } from "react-device-detect";

export default function HeaderSection(props) {
    const classes = useStyles();
    const { name, blocked } = props;
    
    const seo = props.seo || {
        title: "BB Host",
        description: "BB Host, datacenter de qualidade!",
        url: "https://www.bbhost.com.br",
        type: "website",
        languageAlternates: [
            {
                hrefLang: 'en',
                href: "https://www.bbhost.com.br/en",
            },
            {
                hrefLang: 'es',
                href: "https://www.bbhost.com.br/es",
            }
        ]
    };

    const socialItemList = CONTATO.map((item, key) => {
        if(item.nav !== undefined && 
            item.nav && 
            (item.disable !== undefined && !item.disable))
            return (
                <SocialNav item={item} key={key} />
            );
    });

    const navItemList = NAVS.map((nav, key) => {
        return (
            <HeaderNavItem key={key} nav={nav} active_name={name}/>
        );
    });

    const rightNavs = (
        <List className={classes.list}>
            <ActionNav color="transparent" name="painel_client" text="navs::client" route="https://financeiro.bbhost.com.br/" icon={["far", "user"]} />                
                {socialItemList}                   
            <LocationNav />
        </List>
    );

    const leftLinks = (
        <List className={classes.list}>
            {navItemList}
        </List>
    );

    let parallaxSection = <></>;
    if(PAGES[name] != undefined)
        parallaxSection = <HeaderSectionParallax {...PAGES[name]} />;
    if(PAGES[name] != undefined && PAGES[name].sliders != undefined && !isMobile)
        parallaxSection = <HeaderSectionSliderParallax {...PAGES[name]} />;

    const openGraphImage = getBGPath(name);

    if(seo.images == undefined)
    {
        seo.images = [
            {
                url: `${getDomainByENV()}${openGraphImage.src}`,
                width: 800,
                height: 600,
                alt: seo.description,
            },
        ];
    }

    if(blocked)
    {
        return (
            <div>
                <Head>
                    <title>{seo.title}</title>
                    <meta name="description" content={seo.description} />
                </Head>
                <DefaultSeo languageAlternates={seo.languageAlternates} />
                <NextSeo
                    openGraph={{
                        type: seo.type,
                        url: seo.url,
                        title: seo.title,
                        description: seo.description,
                        images: seo.images,
                    }}
                    canonical={seo.url}
                />
                <Header
                    color="transparent"
                    brand_icon
                    fixed
                    changeColorOnScroll={{
                        height: 80,
                        color: "dark",
                    }}
                    index={(name === "home")}
                />
                <Parallax small filter responsive name={name} blocked>
                    {parallaxSection}
                </Parallax>
            </div>
        );
    }
    return (
    <div>
        <Head>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description} />
        </Head>
        <DefaultSeo languageAlternates={seo.languageAlternates} />
        <NextSeo
            openGraph={{
                type: seo.type,
                url: seo.url,
                title: seo.title,
                description: seo.description,
                images: seo.images,
            }}
            canonical={seo.url}
        />
        <Header
            color="transparent"
            brand_icon
            rightLinks={rightNavs}
            leftLinks={leftLinks}
            fixed
            changeColorOnScroll={{
                height: 80,
                color: "dark",
            }}
            index={(name === "home")}
        />
        <Parallax small filter responsive name={name}>
            {parallaxSection}
        </Parallax>
    </div>
    );
}