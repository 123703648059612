const BGParceriaConfig = {
    "default": "https://cdn.bbhost.com.br/images/bg/capa_fundo_dedicado.png",
    "staid": "https://cdn.bbhost.com.br/images/bg/capa_fundo_vps.png"
};

export default function getBGParceriaPath(name)
{    
    if(BGParceriaConfig[name] !== undefined)
        return BGParceriaConfig[name];    

    return BGParceriaConfig[`default`];
}