export default [    
    {
        "text": "Intel Xeon E-2286G",
        "configs": [
            {
                "name": "cpu",
                "detail": "6c/12t - 4.0GHz/4.9GHz",
                "icon": ["fas", "microchip"]
            },
            {
                "name": "ram",
                "detail": "32 GB",
                "icon": ["fas", "memory"]
            },
            {
                "name": "disk",
                "detail": "1x1TB NVMe",
                "icon": ["fas", "hdd"]
            }
        ],
        "price": 1200,
        "link": {
            "color": "info",
            "type": "external",
            "href": "https://financeiro.bbhost.com.br/store/dedicados-brasil/intel-xeon-e-2286g",
        },
        "icon": ["fas", "server"],
        "spotlight": false
    },
    {
        "text": "Intel Xeon E-2388G",
        "configs": [
            {
                "name": "cpu",
                "detail": "8c/16t - 3.2GHz/5.1GHz",
                "icon": ["fas", "microchip"]
            },
            {
                "name": "ram",
                "detail": "64 GB",
                "icon": ["fas", "memory"]
            },
            {
                "name": "disk",
                "detail": "1x1TB NVMe",
                "icon": ["fas", "hdd"]
            }
        ],
        "price": 1500,
        "link": {
            "color": "info",
            "type": "external",
            "href": "https://financeiro.bbhost.com.br/store/dedicados-brasil/intel-xeon-e-2388g",
        },
        "icon": ["fas", "server"],
        "spotlight": true
    },
    {
        "text": "Intel Dual Xeon E5-2680",
        "configs": [
            {
                "name": "cpu",
                "detail": "16/32t - 2.7GHz/3.5GHz",
                "icon": ["fas", "microchip"]
            },
            {
                "name": "ram",
                "detail": "128 GB",
                "icon": ["fas", "memory"]
            },
            {
                "name": "disk",
                "detail": "1TB SSD",
                "icon": ["fas", "hdd"]
            }
        ],
        "price": 850,
        "link": {
            "color": "info",
            "type": "external",
            "href": "https://financeiro.bbhost.com.br/store/dedicados-brasil/intel-dual-xeon-e5-2680",
        },
        "icon": ["fas", "server"],
        "spotlight": false
    }
];