export default [ 
    {
        "name": "discord",
        "text": "Discord",
        "route": "https://financeiro.bbhost.com.br/link.php?id=206",
        "icon": ["fab", "discord"],
        "disable": false,
        "nav": true,
        "justIcon": true,
        "color": "transparent",
        "event": "open_discord"
    },
    {
        "name": "whatsapp",
        "text": "Whatsapp",
        "route": "https://financeiro.bbhost.com.br/link.php?id=193",
        "icon": ["fab", "whatsapp"],
        "disable": false,
        "nav": true,
        "justIcon": true,
        "color": "success",
        "event": "open_whatsapp"
    },
    {
        "name": "telephone",
        "text": "(11) 9 1678-8282",
        "route": "https://financeiro.bbhost.com.br/link.php?id=207",
        "icon": ["fas", "phone"],
        "disable": false,
        "nav": true,
        "justIcon": false,
        "color": "transparent",
        "event": "open_call_phone"
    },
];