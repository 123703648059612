export default [
    {
        "text": "24/7/365",
        "subText": {
            "br": "Um suporte especializado disponível a qualquer momento via ticket, pronto para resolver qualquer dúvida ou problema.",
            "en": "Um suporte especializado disponível a qualquer momento via ticket, pronto para resolver qualquer dúvida ou problema.",
            "es": "Um suporte especializado disponível a qualquer momento via ticket, pronto para resolver qualquer dúvida ou problema."
        },
        "icon": "chat",
        "iconColor": "info"
    },
    {
        "text": {
            "br": "Proteção DDoS Ilimitada",
            "en": "Proteção DDoS Ilimitada",
            "es": "Proteção DDoS Ilimitada"
        },
        "subText": {
            "br": "Tenha a mitigação de seus ataques independente do tamanho, não trabalhamos com null-route ou blackhole!",
            "en": "Tenha a mitigação de seus ataques independente do tamanho, não trabalhamos com null-route ou blackhole!",
            "es": "Tenha a mitigação de seus ataques independente do tamanho, não trabalhamos com null-route ou blackhole!"
        },
        "icon": "security",
        "iconColor": "danger"
    },
    {
        "text": {
            "br": "Sistemas Inteligentes",
            "en": "Sistemas Inteligentes",
            "es": "Sistemas Inteligentes"
        },
        "subText": {
            "br": "Tenha a liberação de seu servidor automaticamente pelos nossos sistemas inteligentes.",
            "en": "Tenha a liberação de seu servidor automaticamente pelos nossos sistemas inteligentes.",
            "es": "Tenha a liberação de seu servidor automaticamente pelos nossos sistemas inteligentes."
        },
        "icon": "verifieduser",
        "iconColor": "success"
    }
];