export default [
    {
        "name": "home",
        "text": {
            "br": "Inicio",
            "en": "Home",
            "es": "Home"
        },
        "type": "link",
        "route": "/"
    },
    {
        "name": "vps",
        "text": "VPS",
        "type": "link",
        "route": "/vps"
    },
    {
        "name": "baremetal",
        "text": "BareMetal",
        "type": "link",
        "route": "/baremetal"
    },
    {
        "name": "colocation",
        "text": "Colocation",
        "type": "link",
        "route": "/colocation"
    },
    {
        "name": "anti_ddos",
        "text": "Anti-DDoS",
        "type": "link",
        "route": "/anti_ddos"
    },
    {
        "name": "infrastructure",
        "text": {
            "br": "Infraestrutura",
            "en": "Infrastructure",
            "es": "Infraestructura"
        },
        "type": "link",
        "route": "/infrastructure"
    }
];